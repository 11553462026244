import React from 'react';
import { connect } from 'react-redux';
import sagaActions from 'actions/node/saga-creators';
import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import './stylesheet.scss';

class AtnDeleteBtnWithConfirmDialog extends React.Component {
  handleAction = (onRequestSuccessful) => {
    const { dispatch, customDeleteAction } = this.props;

    if (customDeleteAction && typeof customDeleteAction === 'function') {
      customDeleteAction();

      return;
    }

    dispatch(
      sagaActions.deleteNodeRequest({
        ...this.props, //view actions/node/saga-creators.js 'deleteNodeRequest' for what options could go in props
        onRequestSuccessful,
      }),
    );
  };

  showModalDelete = () => {
    const handleAction = this.handleAction;

    const {
      onRequestSuccessful,
      title = `${t1('do_you_really_want_to_delete')} ?`,
      content = null,
    } = this.props;

    Modal.confirm({
      centered: true,
      title,
      cancelText: t1('cancel'), //'Hủy bỏ',
      okText: 'OK',
      content: !!content && content,
      onOk() {
        handleAction(onRequestSuccessful);
      },
    });
  };

  render() {
    const { renderComponent, icon } = this.props;
    const titleButton = this.props.titleButton || t1('delete_session');
    if (renderComponent) {
      return renderComponent({ onClick: this.showModalDelete });
    }

    return (
      <span
        className={` cursor-pointer ${
          icon
            ? `${icon} delete-button-with-confirm`
            : 'delete-button-with-confirm--text'
        }`}
        onClick={this.showModalDelete}
      >
        {icon ? '' : `${titleButton}`}
      </span>
    );
  }
}

export default connect()(AtnDeleteBtnWithConfirmDialog);
